import { defineStore } from 'pinia'

export const useDiploTrans = defineStore('diplotrans', {
  state: () => ({
    selections: {
      facs: {},
      diplo: {},
      anno: {}
    }
  }),
  getters: {
  },
  actions: {
  }
})
